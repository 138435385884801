.mainBox {
  min-height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.headingBox {
  opacity: 0.38;
  margin-bottom: 30px;
}

.newButtonBox {
  display: block;
  margin-bottom: 30px;
}

.newButtonBox a {
  border-radius: 30px;
  background-color: rgba(135,
      83,
      244,
      0.75);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  width: 100%;
  color: #ffffff;
  text-decoration: none;
  width: 100%;
  max-width: max-content;
}

.newButtonBox a:hover {
  background-color: rgba(135,
      83,
      244,
      1);
}

.descrBox {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.0333em;
  color: #8753f4;
  margin-bottom: 50px;
}

.dashTextBox {
  padding: 10px;
  background-color: rgba(135,
      83,
      244,
      0.4);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.0333em;
  color: #535353;
  margin-bottom: 20px;
}

.dashTextBox strong {
  font-weight: 700;
}

a.link {
  color: #8753f4;
  text-decoration: none;
}

a.link:hover {
  text-decoration: underline;
}