.header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
}

h2.h2 {
  font-style: normal;
  font-weight: 200;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: -0.08em;
  text-transform: uppercase;
  color: #333333;
}