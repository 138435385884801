.mainHeadingBox {
  margin-bottom: 40px;
}

.errorFormBox {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ff0000;
  margin-bottom: 20px;
}

.resutlText {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 42px;
  color: #8753f4;
  margin-bottom: 20px;
}

.subHeadingBox {
  margin-bottom: 20px;
}

.inputHeadingBox {
  margin-bottom: 14px;
}

.formBox {
  margin-bottom: 40px;
}

@media screen and (min-width: 991px) {
  .formBox {
    margin-bottom: 60px;
  }
}

.inputBox {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 991px) {
  .inputBox {
    margin-bottom: 40px;
  }
}

.inputText {
  width: 100%;
  padding: 0 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  color: #333333;
}

.inputText>div {
  border-radius: 30px;
}

div.inputSelect {
  width: 100%;
  border-radius: 30px;
  padding: 0 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  color: #333333;
}

button.button {
  border-radius: 30px;
  background-color: rgba(135,
      83,
      244,
      0.75);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  padding: 16px 0;
  width: 100%;
  color: #ffffff;
}

button.button:hover {
  background-color: rgba(135,
      83,
      244,
      1);
}

.inputNubmer>div,

.inputNubmerFull>div {
  border-radius: 30px;
}

.inputNubmer input,

.inputNubmerFull input {
  padding: 21.5px 18px;
}

div.inputNubmer,

div.inputNubmerFull {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  color: #333333;
  margin: 0 0 20px 0;
  width: calc(50% - 40px);
}

div.inputNubmerFull {
  width: 100%;
}

@media screen and (min-width: 991px) {
  div.inputNubmer,

  div.inputNubmerFull {
    margin: 0 20px 0 0;
  }
}

.results {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
  color: #8753f4;
  margin: 30px 0 50px 0;
}

button.buttonSendTrial {
  width: 100%;
  background-color: #68ffad;
  border-radius: 33px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #333333;
  padding: 20px 30px;
  opacity: 0.6;
  margin-bottom: 40px;
}

button.buttonSendTrial:hover {
  background-color: #53ca89;
}

@media screen and (min-width: 991px) {
  button.buttonSendTrial {
    font-size: 26px;
    line-height: 36px;
    padding: 30px 40px;
  }
}

button.buttonSend {
  width: 100%;
  background-color: #68ffad;
  border-radius: 33px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
  color: #333333;
  padding: 20px 30px;
}

button.buttonSend:hover {
  background-color: #53ca89;
}

@media screen and (min-width: 991px) {
  button.buttonSend {
    font-size: 33px;
    line-height: 40px;
    padding: 30px 40px;
  }
}