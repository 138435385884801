button.button {
  border-radius: 30px;
  background-color: rgba(135, 83, 244, 0.75);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  padding: 16px 0;
  width: 100%;
  color: #ffffff;
  margin: 10px;
  transition: all ease 0.3s;
}

button.button:hover {
  background-color: rgba(135, 83, 244, 1);
}

.display-none {
  display: none;
}
