.mainHeadingBox {
  margin-bottom: 40px;
}

.errorFormBox {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ff0000;
  margin-bottom: 20px;
}

.resutlText {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 42px;
  color: #8753f4;
  margin-bottom: 20px;
}

.subHeadingBox {
  margin-bottom: 40px;
}

.subHeadingBox h2 {
  color: #333333;
  text-transform: unset;
}

.formBox {
  margin-bottom: 40px;
}

@media screen and (min-width: 991px) {
  .formBox {
    margin-bottom: 60px;
  }
}

.inputBox {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 991px) {
  .inputBox {
    margin-bottom: 40px;
  }
}

.inputText {
  width: 100%;
  padding: 0 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  color: #333333;
}

.inputText > div {
  border-radius: 30px;
}

.afterFormBox {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 20px;
}

.afterFormBox a {
  text-decoration: none;
}

button.button {
  border-radius: 30px;
  background-color: rgba(135, 83, 244, 0.75);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  padding: 16px 0;
  width: 100%;
  color: #ffffff;
}

button.button:hover {
  background-color: rgba(135, 83, 244, 1);
}
