.h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 50px;
  letter-spacing: -0.05em;
  color: #333333;
}

.h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 53px;
  letter-spacing: -0.0333em;
  color: #8753f4;
}

.h3 {
  font-weight: 200;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: -0.08em;
  text-transform: uppercase;
  color: #333333;
}

.h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}