* {
  margin: 0;
  padding: 0;
}

.pageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

.mainWrapper {
  max-width: 800px;
  width: 100%;
  padding: 0 15px;
  margin: 50px 0;
}

@media screen and (min-width: 991px) {
  .mainWrapper {
    margin: 100px 0;
  }
}
