.mainBox {
  height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.headingBox {
  opacity: 0.38;
  margin-bottom: 30px;
}

.paymentText {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.0333em;
  color: #535353;
  margin-bottom: 40px;
}

.emailText {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.0333em;
  color: #535353;
  margin-bottom: 40px;
}

.emailText strong {
  font-weight: 700;
}

.dashboardText {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.0333em;

  color: #535353;
}

.dashboardText span {
  color: #8753f4;
}

.dashboardText span:hover {
  cursor: pointer;
  text-decoration: underline;
}