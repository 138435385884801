main.landingMain{
  position: relative;
  overflow: auto;
  scroll-snap-type: y mandatory;
  height: 100vh;
  scroll-behavior: smooth;
}

.sectionCommon {
  height: 100vh;
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
}

.firstSection {
  background-color: #fff;
}

.contentWrapper {
  max-width: 800px;
  width: 100%;
  padding: 0 15px;
}

.logoText {
  font-style: normal;
  font-weight: 900;
  font-size: 56px;
  line-height: 68px;
  letter-spacing: -0.06em;
  color: #000000;
  margin-bottom: 10%;
}

.sectionText {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 66px;
  line-height: 64px;
  letter-spacing: -0.03em;
  color: #000000;
  margin-bottom: 50px;
}

.sectionText strong {
  font-weight: 700;
  display: block;
}

@media screen and (max-width: 991px) {
  .sectionText {
    font-size: 48px;
    line-height: 46px;
  }
}

a.firstSectionLink {
  display: block;
  width: max-content;
  background-color: #2b2b2b;
  padding: 20px 70px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.3s ease-in;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.firstSectionLink:hover {
  text-decoration: none;
  background-color: #000;
}

@media screen and (max-width: 991px) {
  a.firstSectionLink {
    width: auto;
    font-size: 26px;
    line-height: 33px;
    padding: 20px 15px;
  }
}

/* secondSection */

.secondSection {
  background-color: #0f0738;
}

.smallWrapper {
  max-width: 500px;
}

.secondHeading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 54px;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-bottom: 44px;
}

.secondHeading span {
  display: block;
}

@media screen and (max-width: 991px) {
  .secondHeading {
    font-size: 36px;
    line-height: 35px;
    margin-bottom: 30px;
  }
}

.knowNumbers {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.knowNumbersHeading {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.knowNumbers p:not(:last-child) {
  margin-bottom: 30px;
}

.knowNumbers p span {
  margin-left: 10px;
  display: inline-flex;
}

.knowNumbers p.noMargin {
  margin: 0 0 5px 0;
}

.knowNumbers p strong {
  font-weight: bold;
}

@media screen and (max-width: 991px) {
  .knowNumbers {
    font-size: 22px;
    line-height: 26px;
  }
}

.inputText {
  height: 33px;
  text-align: center;
  padding: 0;
  color: white;
  font-size: 18px;
  width: 70px;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid transparent;
  animation: blink 2s ease infinite alternate;
  -webkit-animation: blink 2s ease infinite alternate;
}

@-webkit-keyframes blink {
  50% {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
@keyframes blink {
  50% {
    border-color: rgba(255, 255, 255, 0.5);
  }
}

@media screen and (max-width: 991px) {
  .inputText {
    height: 25px;
  }
}

/* Chrome, Safari, Edge, Opera */
.inputText::-webkit-outer-spin-button,
.inputText::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]:focus-visible {
  outline: none;
}

/* thirdSection */
.thirdSection {
  background-color: #9da5c8;
}

.thirdHeading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-bottom: 40px;
}

@media screen and (max-width: 991px) {
  .thirdHeading {
    font-size: 36px;
    line-height: 40px;
  }
}

.thirdHeading span {
  background: radial-gradient(
      30.97% 222.44% at 4.85% 9.67%,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    )
    #fed8a2;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

a.thirdSectionLInk {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 460px;
  margin: 0 auto 40px auto;
  background: linear-gradient(90deg, #ffc464 0%, #eb96ad 100%);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  text-decoration: none;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  padding: 20px 0;
  transition: all 0.3s ease-in;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

a.thirdSectionLInk:hover {
  background: linear-gradient(90deg, #ffc464 0%, #e8aebd 100%);
}

@media screen and (max-width: 991px) {
  a.thirdSectionLInk {
    font-size: 30px;
    line-height: 38px;
  }
}

@media screen and (max-width: 991px) {
  a.thirdSectionLInk {
    font-size: 26px;
    line-height: 34px;
  }
}

.imageBox {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .imageBox {
    margin-bottom: 50px;
  }

  .imageBox img {
    max-width: 100%;
  }
}

.nav {
  max-width: 768px;
}

.nav ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.nav ul li a {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  text-decoration: none;
  line-height: 33px;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.nav ul li:nth-child(1) a,
.nav ul li:nth-child(2) a {
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .nav ul {
    flex-wrap: wrap;
  }

  .nav ul li:nth-child(1),
  .nav ul li:nth-child(2) {
    width: 50%;
    margin-bottom: 40px;
  }

  .nav ul li:nth-child(3) {
    margin-bottom: 15px;
  }

  .nav ul li:nth-child(3),
  .nav ul li:nth-child(4) {
    width: 100%;
  }
}
