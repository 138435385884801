h1.h1 {
  margin-bottom: 40px;
}

.tagBox {
  margin-bottom: 40px;
}

.tagBox .MUIchip:nth-child(2n + 1) {
  border-color: #8753f4;
}

.tagBox .MUIchip:nth-child(even) {
  border-color: #8ba7c8;
}

.tagBox .MUIchip:nth-child(odd) {
  border-color: #7bd7b9;
}

.signUpBox {
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 93.52%;
  letter-spacing: -0.05em;
  color: #333333;
  opacity: 0.4;
  text-align: center;
}

.getTextBox {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 41px;
  letter-spacing: -0.05em;
  color: #333333;
}

button.getLeadsBtn {
  width: 100%;
  text-transform: uppercase;
  background: linear-gradient(180deg,
      #68ffad 0%,
      #12ed77 100%);
  border-radius: 33px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #333333;
  padding: 30px 40px;
  transition: all 0.4s ease-in;
  margin-bottom: 50px;
}

@media screen and (min-width: 991px) {
  .button.getLeadsBtn {
    font-size: 33px;
    line-height: 40px;
  }
}

button.getLeadsBtn:hover {
  background: linear-gradient(180deg,
      #12ed77 0%,
      #12ed77 100%);
}

.riskTextBox {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #000000;
}

.nav ul {
  list-style: none;
}

.nav ul li a {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: -0.035em;
  color: #333333;
  opacity: 0.3;
  transition: all 0.5s linear;
}

.nav ul li a:hover {
  opacity: 0.8;
}

.nav ul li:not(:last-child) {
  margin-bottom: 5px;
}

/* end style */

.connectHeader {
  color: #8753f4;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.0333em;
  margin-bottom: 25px;
}

.connectHeader span {
  font-weight: 100;
  color: #000000;
}

.formBox {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  flex-direction: column;
}

@media screen and (min-width: 991px) {
  .formBox {
    flex-direction: row;
  }
}

.textResult {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  text-align: center;
}