.mainHeadingBox {
  margin-bottom: 40px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  letter-spacing: -0.0333em;
  color: #535353;
}